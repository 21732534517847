import firebase from 'firebase/app'
import 'firebase/storage'
import pos from '@/services/local'

export default {
  async onUpload(uid, ref, image, ext) {
    let fileTMP = new Date().getTime()

    let directoryPath = 'resources/' + uid + '/resources/products/' + ref
    let refpath = directoryPath + '/' + fileTMP + '.' + ext
    
    try {
      const snapshot = await firebase
        .storage()
        .ref(refpath)
        .putString(image, 'data_url')
      
      return await snapshot.ref.getDownloadURL()
    } catch (e) {
      return e.message
    }
  },
  async onUpload2(directoryPath, image) {
    const refpath = directoryPath
    try {
      const snapshot = await firebase
        .storage()
        .ref(refpath)
        .putString(image, 'data_url')
      return await snapshot.ref.getDownloadURL()
    } catch (e) {
      return e.message
    }
  },
async deleteImage(uid, shopObjectId, remoteImagePath) {
  const sizes = ['100x100', '200x200', '300x300', '400x400'];
  
  const pathsToDelete = [
    remoteImagePath,
    ...sizes.map(size => remoteImagePath.replace(/\.(png|jpg|jpeg|webp)/, `_${size}.webp`)),
    ...sizes.map(size => remoteImagePath.replace(/\.(png|jpg|jpeg|webp)/, `_${size}.jpeg`)),
    ...sizes.map(size => remoteImagePath.replace('?alt=media', `_${size}?alt=media`))
  ];

  const deleteRemoteImage = async (path) => {
    const data = { remoteImagePath: path };
    try {
      await pos({
        method: 'post',
        url: `/api/v1.0/image/${uid}/${shopObjectId}/deleteremoteimg`,
        data: data,
      });
    } catch (error) {
      console.log(`Error deleting ${path}:`, error);
    }
  };

  for (const path of new Set(pathsToDelete)) {
    await deleteRemoteImage(path);
  }
},

  async deleteImage2(directoryPath) {
    const refpath = directoryPath
    try {
      return await firebase
        .storage()
        .ref(refpath)
        .delete()
    } catch (e) {
      return e.message
    }
  },
}
