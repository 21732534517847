export default {
  getExtension(encoded) {
    var result = null

    if (typeof encoded !== 'string') {
      return result
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

    if (mime && mime.length) {
      switch (mime[1]) {
        case 'image/png':
          result = 'png'
          break
        case 'image/jpeg':
          result = 'jpg'
          break
        case 'image/jpg':
          result = 'jpg'
          break
        default:
          result = null
          break
      }
    }

    return result
  },
}
